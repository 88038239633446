import router from "@/router/index";
import store from "@/store";

//beforeEach 全局前置守卫
router.beforeEach((to, from, next) => {
  const whiteList = [
    //白名单
    "/login",
  ];
  let token = store.getters.GET_TOKEN;
  let hasRoutes = store.state.hasRoutes;
  let menuList = store.getters.GET_MENULIST;
  if (token) {
    if (!hasRoutes) {
      bindRoute(menuList);
      store.commit("SET_ROUTES_STATE", true);
      next(to);
    }
    next();
  } else {
    console.log("else");
    if (whiteList.includes(to.path)) {
      //如果在白名单里，放行
      console.log("白名单放行");
      next();
    } else {
      console.log("重定向到/login");
      next("/login");
    }
  }
});
//动态绑定路由
const bindRoute = (menuList) => {
  if (menuList) {
    let newRoutes = router.options.routes;
    menuList.forEach((menu) => {
      if (menu.son) {
        menu.son.forEach((m) => {
          //菜单转成路由
          let route = menuToRoute(m, menu.name);
          if (route) {
            newRoutes[0].children.push(route); //添加到路由管理
          }
        });
      }
      if (menu.son.length === 0) {
        let route = menuToRoute(menu, menu.name);
        if (route) {
          newRoutes[0].children.push(route); //添加到路由管理
        }
      }
    });
    //重新添加到路由
    newRoutes.forEach((route) => {
      router.addRoute(route);
    });
  }
};

//菜单对象转成路由对象
const menuToRoute = (menu, parentName) => {
  if (!menu.component) {
    //没有component就不用转了(核对下数据库)
    let route = {
      name: menu.name,
      path: menu.path,
      meta: {
        parentName: parentName,
      },
    };
    route.component = () => import("@/views/" + menu.component + ".vue");
    return route;
  } else {
    let route = {
      name: menu.name,
      path: menu.path,
      meta: {
        parentName: parentName,
      },
    };
    route.component = () => import("@/views/" + menu.component + ".vue");
    return route;
  }
};

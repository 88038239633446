import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "首页",
    component: () => import("@/layout/index"),
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "首页",
        component: () => import("@/views/index/index"),
      },
      {
        path: "/userCenter",
        name: "个人中心",
        component: () => import("@/views/userCenter/index"),
      },
      {
        path: "/configuration",
        name: "游戏配置",
        component: () => import("@/views/game/type/configuration/index.vue"),
      },
      {
        path: "/priceConfig",
        name: "价格配置",
        component: () => import("@/views/game/type/priceConfig/index.vue"),
      },
      {
        path: "/goods/add",
        name: "发布商品",
        component: () => import("@/views/goods/add/index.vue"),
      },
      {
        path: "/goods/details",
        name: "商品详情",
        component: () => import("@/views/goods/details/index.vue"),
      },
      {
        path: "/goods/edit",
        name: "编辑商品",
        component: () => import("@/views/goods/edit/index.vue"),
      },
      {
        path: "/bargaining/participate",
        name: "参与砍价",
        component: () => import("@/views/bargaining/participate/index.vue"),
      },
      {
        path: "/bidding/participate",
        name: "参与竞价",
        component: () => import("@/views/bidding/participate/index.vue"),
      },
      {
        path: "/article/edit",
        name: "编辑文章协议",
        component: () => import("@/views/article/edit/index.vue"),
      },
      {
        path: "/goodsOrder/details",
        name: "商品订单详情",
        component: () => import("@/views/order/goods/details/index.vue"),
      },
      {
        path: "/identifyOrder/details",
        name: "鉴定订单详情",
        component: () => import("@/views/order/identify/details/index.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
